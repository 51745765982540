/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, State} from 'vuex-class'
import { CustomerState } from '@/store/customer/types';
import { ModelAccountState } from '@/store/modelAccount/types'
const namespace: string = 'modelAccount';

export default class ModelAccountEmailConfirmationPage extends Vue {
	@State('modelAccount') modelAccount: ModelAccountState | undefined;

	onSubmit() {
		this.$router.push({name: 'login'}).then(r => r);
	}
}
